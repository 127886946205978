(function() {
    var subscriptionSelector = '.weekly__form';
    var forms = document.querySelectorAll(subscriptionSelector);

    if (!forms) return;

    forms.forEach(function (form) {
        form.addEventListener('submit', onFormSubmit);
    });

    function onFormSubmit(e) {
        var currentForm = e.target;
        e.preventDefault();

        postData(`/api/subscribe/`, serialize(currentForm))
            .then(function(data) {
                currentForm.classList.add('weekly__form_hidden');

                if (data.status === 'ok') {
                    currentForm.parentElement.querySelector('.weekly__success').classList.add('weekly__success_visible');
                } else if (data.status === 'already') {
                    currentForm.parentElement.querySelector('.weekly__already').classList.add('weekly__already_visible');
                } else {
                    currentForm.parentElement.querySelector('.weekly__error').classList.add('weekly__error_visible');
                }
            })
            .catch(function (error) {
                console.error(error);
                currentForm.classList.add('weekly__form_hidden');
                currentForm.parentElement.querySelector('.weekly__error').classList.add('weekly__error_visible');
            });
    }

    function serialize (form) {
        var serialized = {};

        for (var i = 0; i < form.elements.length; i++) {
            var field = form.elements[i];

            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized[field.name] = field.value;
            }
        }

        return JSON.stringify(serialized);
    }

    function postData(url, data) {
        return fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: data,
            })
            .then(function(response) {
                return response.json();
            });
    }
})();
